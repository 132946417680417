import { useMemo, useState } from 'react';
import { useOrders } from '../../utils/api.hook';
import { OrderStatisticsComponent } from './order-statistics.component';
import { forEach, map } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

export function OrderStatisticsContainer() {
  const [redeemDate, setRedeemDate] = useState<[Dayjs, Dayjs]>([
    dayjs(),
    dayjs(),
  ]);
  const {
    data: { orders } = {},
    isLoading,
    isFetching,
  } = useOrders({
    redeemTimeRange: map(redeemDate, (date) => date.toISOString()),
    state: ['已確認'],
  });
  const [locationCounts, providerCounts] = useMemo(() => {
    const locationResult: Record<string, number> = {};
    const providerResult: Record<string, number> = {};
    forEach(orders, (order) => {
      const { quantity, address, providerName } = order;

      locationResult[address] = (locationResult[address] || 0) + quantity;
      providerResult[providerName] =
        (providerResult[providerName] || 0) + quantity;
    });

    return [
      map(locationResult, (value, id) => ({ id, value })),
      map(providerResult, (value, id) => ({ id, value })),
    ];
  }, [orders]);

  return (
    <OrderStatisticsComponent
      isLoading={isLoading || isFetching}
      onDateChange={setRedeemDate}
      redeemDate={redeemDate}
      locationCounts={locationCounts || []}
      providerCounts={providerCounts || []}
    />
  );
}
