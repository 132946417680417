import { useEffect, useMemo, useState } from 'react';
import { groupBy, isEmpty, keys, map, reduce, sortBy } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  useLocationTags,
  useListProviders,
  useScheduleMeals,
  useUpdateScheduleMeal,
  useDeleteScheduleMeal,
  useUpdateScheduleMealStatus,
  useUpdateScheduleMealsRank,
  useScheduleMealsNote,
  useUpateScheduleMealsNote,
  useMealsProviderColor,
  useUpateMealsProviderColor,
} from '../../utils/api.hook';

import { ScheduleMealsComponent } from './schedule-meals.component';
import schedules from '../schedules';

export function ScheduleMealsContainer() {
  const [selectDate, setSelectDate] = useState(dayjs().day(1));
  const [listData, setListData] = useState<
    Record<
      string,
      {
        id: string;
        name: string;
        data: schedules.MappingGroupByTimestamp;
      }
    >
  >({});

  const [startTime, endTime] = useMemo(() => {
    const distanceDay = 14;

    return [
      selectDate.startOf('day').add(0, 'days').valueOf(),
      selectDate
        .startOf('day')
        .add(distanceDay - 1, 'days')
        .valueOf(),
    ];
  }, [selectDate]);

  const { data: { providers } = {} } = useListProviders();
  const { data: { deliveryMeals = [] } = {} } = useScheduleMeals({
    startTime,
    endTime,
  });
  const { data: { deliveryMeals: todayDeliveryMeals = [] } = {} } =
    useScheduleMeals({
      startTime: dayjs().startOf('day').valueOf(),
      endTime: dayjs().endOf('day').valueOf(),
    });
  const { mutateAsync: updateScheduleMeals } = useUpdateScheduleMeal();
  const { mutateAsync: deleteScheduleMeals } = useDeleteScheduleMeal();
  const { mutateAsync: updateScheduleMealStatus } =
    useUpdateScheduleMealStatus();
  const { mutateAsync: updateScheduleMealsRank } = useUpdateScheduleMealsRank();
  const { data: noteData = [] } = useScheduleMealsNote({
    startTime,
    endTime,
  });
  const { mutateAsync: upateScheduleMealsNote } = useUpateScheduleMealsNote();
  const { data: colorData = [] } = useMealsProviderColor();
  const { mutateAsync: upateMealsProviderColor } = useUpateMealsProviderColor();
  const { data: { locationTags } = {} } = useLocationTags();

  const providerTagsInformation = useMemo(() => {
    if (isEmpty(providers)) {
      return {
        options: [],
        mappingObject: {},
      };
    }

    const sortedOptions = sortBy(providers, (provider) => provider.type);
    const optionsGroupByType = groupBy(sortedOptions, 'type');

    return {
      options: keys(optionsGroupByType).map((typeKey) => ({
        label: typeKey,
        options: optionsGroupByType[typeKey].map(
          (item: { id: string; name: string }) => ({
            label: item.name,
            value: item.id,
          })
        ),
      })),
      mappingObject: providers.reduce(
        (accumulate: { [id: string]: {} }, item: { id: string }) => {
          accumulate[item.id] = item;
          return accumulate;
        },
        {}
      ),
    };
  }, [providers]);

  const noteMapping = useMemo(() => {
    return reduce(
      noteData,
      (
        accumulate: { [time: number]: { [locationTagId: string]: string } },
        item: {
          locationTagId: string;
          time: number;
          note: string;
        }
      ) => {
        if (isEmpty(accumulate[item.time])) {
          accumulate[item.time] = {};
        }
        accumulate[item.time][item.locationTagId] = item.note;

        return accumulate;
      },
      {}
    );
  }, [noteData]);

  useEffect(() => {
    const initListData = async () => {
      const distanceDay = 14;

      let timestampAccumulate: { [timestamp: number]: schedules.CellData[] } =
        {};
      let timestampKey: Dayjs;

      for (let index = 0; index < distanceDay; index++) {
        timestampKey = selectDate.startOf('day').add(index, 'days');
        if (timestampKey.get('day') > 0 && timestampKey.get('day') < 6) {
          timestampAccumulate[timestampKey.valueOf()] = [];
        }
      }

      const mappingGroupByTags = groupBy(deliveryMeals, 'locationTag.id');
      let mappingGroupByTimestamp: schedules.MappingGroupByTimestamp = {};

      const initData = reduce(
        locationTags,
        (
          accumulate: {
            [id: string]: schedules.LocationTag & {
              data: schedules.MappingGroupByTimestamp;
            };
          },
          item: schedules.LocationTag
        ) => {
          let data: schedules.MappingGroupByTimestamp = {};
          if (!isEmpty(mappingGroupByTags[item.id])) {
            mappingGroupByTimestamp = groupBy(
              mappingGroupByTags[item.id],
              'time'
            );

            for (let timestamp of keys(timestampAccumulate)) {
              data[timestamp] = !isEmpty(mappingGroupByTimestamp[timestamp])
                ? map(mappingGroupByTimestamp[timestamp], (item) => ({
                    timestamp,
                    id: item.id,
                    provider: item.provider,
                    locationTag: item.locationTag,
                    rank: item.rank,
                    status: item.status,
                    needToRecycleBags: item.needToRecycleBags,
                  }))
                : [];
            }
          } else {
            data = timestampAccumulate;
          }

          accumulate[item.id] = {
            ...item,
            data,
          };

          return accumulate;
        },
        {}
      );

      setListData(initData);
    };

    if (selectDate.isValid() && !isEmpty(locationTags)) {
      initListData();
    }
  }, [deliveryMeals, selectDate, locationTags]);

  return (
    <DndProvider debugMode backend={HTML5Backend}>
      <ScheduleMealsComponent
        locationTags={locationTags}
        providerTagsInformation={providerTagsInformation}
        updateScheduleMeals={updateScheduleMeals}
        deleteScheduleMeals={deleteScheduleMeals}
        updateScheduleMealStatus={updateScheduleMealStatus}
        updateScheduleMealsRank={updateScheduleMealsRank}
        upateScheduleMealsNote={upateScheduleMealsNote}
        upateMealsProviderColor={upateMealsProviderColor}
        listData={listData}
        noteData={noteMapping}
        colorData={colorData}
        selectDate={selectDate}
        setSelectDate={setSelectDate}
        todayDeliveryMeals={todayDeliveryMeals}
      />
    </DndProvider>
  );
}
